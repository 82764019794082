<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%"
  >
    <v-alert
      v-if="alert.visible"
      class="rounded-xl"
      transition="scale-transition"
      :type="alert.type"
      dark
    >
      {{ alert.text }}
    </v-alert>
    <h2
      class="mb-6"
      :class="{ 'text-h6': $vuetify.breakpoint.smAndDown}"
    >
      <v-btn
        id="back-arrow"
        class="ma-1"
        rounded
        text
        outlined
        @click="navigateToLogin"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Skapa Företagskonto
    </h2>
    <v-form
      ref="registerCompanyForm"
      v-model="registerCompanyFormValid"
      class="d-flex flex-column"
      @submit.prevent="onSubmitRegister"
    >
      <v-text-field
        id="org-input"
        v-model="organisationNumber"
        class="mb-4"
        label="Organisationsnummer"
        color="accent"
        :rules="[rules.required, rules.organisationNumber]"
        :loading="loading"
        type="tel"
        hint="Ange organisationsnummer"
        persistent-hint
        outlined
        rounded
        required
      />
      <v-text-field
        id="nin-input"
        v-model="nin"
        class="mb-4"
        :label="$t('Pages.Auth.nin')"
        hint="GigPort använder Mobilt BankID för att verifiera din identitet"
        persistent-hint
        color="accent"
        type="tel"
        :rules="[rules.nin_required, rules.nin_length]"
        :maxlength="12"
        counter
        outlined
        rounded
        required
      />
      <v-text-field
        id="email-input"
        v-model="email"
        class="mb-4"
        :maxlength="50"
        :rules="[rules.email_required, rules.email]"
        color="accent"
        :label="$t('Pages.Profile.email')"
        hint="Notiser om nya uppdrag skickas hit"
        persistent-hint
        type="email"
        outlined
        rounded
        required
      />
      <v-text-field
        id="phone-input"
        v-model="phone"
        class="mb-4"
        :maxlength="13"
        color="accent"
        type="tel"
        hint="Hit kan du bli kontaktad"
        persistent-hint
        :rules="[rules.phone]"
        outlined
        rounded
        :label="$t('Common.phone_number')"
      />
      <v-btn
        v-if="!isAnyMobileDevice"
        id="login-bankid-btn"
        type="submit"
        class="mb-6"
        light
        rounded
        depressed
        x-large
      >
        <img
          :src="bankidBlack"
          alt="BankID logo"
          style="width: 40px; margin-right: 4px"
        >
        Slutför registreringen
      </v-btn>
      <v-btn
        v-else
        id="open-bankid-btn"
        type="submit"
        class="mb-6"
        light
        rounded
        depressed
        x-large
      >
        <img
          :src="bankidBlack"
          alt="BankID logo"
          style="width: 40px; margin-right: 4px"
        >
        BankID på den här enheten
      </v-btn>
      <v-btn
        v-if="isAnyMobileDevice"
        color="secondary"
        class="secondary--text mb-6"
        rounded
        outlined
        x-large
        depressed
        @click="onStartBankIDOnAnotherDevice"
      >
        BankID på annan enhet
      </v-btn>
      <div class="text-subtitle-1 mb-2">
        Villkor
      </div>
      <div class="text-body-2 mb-3">
        När du registrerar ett företagskonto accepterar du GigPorts Allmänna (där information om behandling av personuppgifter ingår).
      </div>
      <div>
        <v-icon color="#FFB74D">
          mdi-open-in-new
        </v-icon>
        <router-link
          :to="{ name: 'tos-users' }"
          target="_blank"
          rel="noreferrer"
          v-text="$t('Footer.terms')"
        />
      </div>
      <div>
        <v-icon color="#FFB74D">
          mdi-open-in-new
        </v-icon>
        <router-link
          :to="{ name: 'privacy-policy' }"
          target="_blank"
          rel="noreferrer"
          v-text="$t('Footer.privacy')"
        />
      </div>
    </v-form>
    <QRCodeDialog
      v-if="isCollecting"
      :show="isCollecting"
      :qrcode-type="'register'"
      :registration="registration"
      @cancel-login="onCancelLogin"
    />
  </div>
</template>

<script>
import bankidBlack from '@/assets/img/bankid_black.svg'
import { rules } from '@/config/validation-rules.js'
import { AUTH_REGISTER_START, AUTH_REGISTER_COLLECT, AUTH_REFRESH_REGISTER_QRCODE, AUTH_REGISTER } from '@/store/actions.type'
import QRCodeDialog from './QRCodeDialog.vue'

export default {
  name: 'RegisterCompany',
  components: {
    QRCodeDialog
  },
  data () {
    return {
      bankidBlack,
      registerCompanyFormValid: true,
      isCollecting: false,
      organisationNumber: '',
      rules,
      nin: '',
      email: '',
      phone: '',
      loading: false,
      alert: {
        visible: false,
        text: '',
        type: 'error'
      },
      isSubmitting: false,
      sameDeviceUrl: null,
      registration: {}
    }
  },
  computed: {
    isAnyMobileDevice () {
      return this.isMobileDevice() || this.isSafariOniOS() || this.isChromeOnAndroid()
    }
  },
  watch: {
    '$route.query.orderRef': 'handleOrderRefChange'
  },
  beforeMount () {
    if (this.$route.query.orderRef) {
      this.isCollecting = true
      this.orderRef = this.$route.query.orderRef
      this.collect(this.orderRef)
    }
  },
  methods: {
    navigateToLogin () {
      this.$router.push({ name: 'accounts-login' })
    },
    onStartBankIDOnSameDevice () {
      window.open(this.sameDeviceUrl, '_blank')
    },
    onStartBankIDOnAnotherDevice () {
      this.isCollecting = true
    },
    onCancelLogin () {
      this.isCollecting = false
    },
    isMobileDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /android|ipad|iphone|ipod/i.test(userAgent)
    },
    isSafariOniOS () {
      return /iP(ad|od|hone).*Safari/i.test(navigator.userAgent) && !(/Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent))
    },
    isChromeOnAndroid () {
      return /Android/i.test(navigator.userAgent) && /Chrome/i.test(navigator.userAgent)
    },
    onSubmitRegister () {
      if (this.$refs.registerCompanyForm.validate()) {
        this.isSubmitting = true
        const registration = {
          terms: true,
          email: this.email,
          userType: 'customer',
          nationalIdentityNumber: this.nin,
          organisationNumber: this.organisationNumber,
          phone: this.phone
        }
        this.registration = registration
        if (this.isAnyMobileDevice) {
          this.registerWithNin(registration)
        } else {
          this.startRegisterCompany(registration)
        }
      }
    },
    startRegisterCompany (registration) {
      this.$store.dispatch(AUTH_REGISTER_START, registration).then(res => {
        this.isCollecting = true
        this.$store.dispatch(AUTH_REFRESH_REGISTER_QRCODE, res.data).then(res => {
          if (res.token && res.status === 'complete') {
            this.$store.dispatch('GET_USER')
            this.$router.push({ name: 'dashboard' })
          }
        }).catch(err => {
          this.$root.$emit('errorMessage', err.response)
        })
      }).catch(err => {
        this.isSubmitting = false
        this.handleRegistrationError(err)
      })
    },
    handleRegistrationError (err) {
      if (err.response.data.errorCode === '409E002') {
        this.alert.text = this.$t('Validation.email_in_use')
      } else if (err.response.data.errorCode === '409E003') {
        this.alert.text = this.$t('Validation.account_with_nin')
      }
      this.alert.visible = true
    },
    handleOrderRefChange (newVal) {
      if (newVal) {
        this.orderRef = newVal
        this.isCollecting = true
        this.collect(newVal)
      }
    },
    registerWithNin (registration) {
      this.$store.dispatch(AUTH_REGISTER, registration).then(res => {
        let redirectUrl = window.location.href
        redirectUrl += this.$route.query.redirect ? `&orderRef=${res.data.orderRef}` : `?orderRef=${res.data.orderRef}`
        this.sameDeviceUrl = this.getLaunchUrlByDevice(res.data.autoStartToken, redirectUrl)
        setTimeout(() => {
          window.open(this.sameDeviceUrl)
        })
      }).catch(err => {
        this.isSubmitting = false
        this.handleRegistrationError(err)
      })
    },
    collect (orderReference) {
      const payload = { orderRef: orderReference }
      this.$store.dispatch(AUTH_REGISTER_COLLECT, payload).then(({ data }) => {
        this.$router.push({ name: 'dashboard' })
      }).catch(err => {
        console.error(err)
        this.windowModel = 1
        if (err.status === 403 && err.data.errorCode === '403E005') {
          // TODO: Replace createAlert with inline alerts
          // this.createAlert('Too many login attempts, please wait 30 seconds.', 'priority_high', 'warning')
        } else {
          this.$root.$emit('errorMessage', err.response)
        }
      })
    },
    getLaunchUrlByDevice (autoStartToken, redirectUrl) {
      const baseUrl = this.isAnyMobileDevice ? 'https://app.bankid.com/?autostarttoken=' : 'bankid:///?autostarttoken='
      return `${baseUrl}${autoStartToken}&redirect=${encodeURIComponent(redirectUrl)}`
    }
  }
}
</script>
